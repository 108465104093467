export const TYPE_INPUT_PRIMARY = "TYPE_INPUT_PRIMARY";
export const TYPE_INPUT_PHONE = "TYPE_INPUT_PHONE";
export const TYPE_INPUT_YEAR = "TYPE_INPUT_YEAR";
export const TYPE_INPUT_ADDRESS = "TYPE_INPUT_ADDRESS";
export const TYPE_INPUT_DATE = "TYPE_INPUT_DATE";
export const TYPE_INPUT_DIGIT = "TYPE_INPUT_DIGIT";
export const TYPE_INPUT_TAX_ID = "TYPE_INPUT_TAX_ID";
export const TYPE_INPUT_VIN = "TYPE_INPUT_VIN";
export const TYPE_INPUT_CURRENCY = "TYPE_INPUT_CURRENCY";

export const VIEW_SOURCE_ADMIN = "VIEW_SOURCE_ADMIN";
export const VIEW_SOURCE_ZOR = "VIEW_SOURCE_ZOR";
export const VIEW_SOURCE_ZEE = "VIEW_SOURCE_ZEE";
export const VIEW_SOURCE_UNAUTHORIZED = "VIEW_SOURCE_UNAUTHORIZED";

export const VIEW_MODE_UPLOAD_FORM = "VIEW_MODE_UPLOAD_FORM";
export const VIEW_MODE_CONTACT_LIST = "VIEW_MODE_CONTACT_LIST";

export const FORM_VIEW = "FORM_VIEW";
export const FORM_EDIT = "FORM_EDIT";

export const SECTION_KEY_ENTITY = "entity";
export const SECTION_LABEL_ENTITY = "Business Entity";
export const SECTION_KEY_POC = "poc";
export const SECTION_LABEL_POC = "POC";
export const SECTION_LABEL_POC_ZEE = "Users";
export const SECTION_KEY_UNIQUE = "questions";
export const SECTION_LABEL_UNIQUE = "Questions";
export const SECTION_KEY_DRIVERS = "drivers";
export const SECTION_LABEL_DRIVERS = "Drivers";
export const SECTION_KEY_VEHICLES = "vehicles";
export const SECTION_LABEL_VEHICLES = "Vehicles";
export const SECTION_KEY_BUILD_OUT = "build-out";
export const SECTION_LABEL_BUILD_OUT = "Build-Out";
export const SECTION_KEY_PAYROLL = "payroll";
export const SECTION_LABEL_PAYROLL = "Payroll";
export const SECTION_KEY_SUBCONTRACTOR = "subcontractor";
export const SECTION_LABEL_SUBCONTRACTOR = "Subcontractor";
export const SECTION_KEY_PROPERTY = "property";
export const SECTION_LABEL_PROPERTY = "Property";
export const SECTION_KEY_EQUIPMENT = "equipment";
export const SECTION_LABEL_EQUIPMENT = "Equipment";
export const SECTION_KEY_DOCUMENT = "document";
export const SECTION_LABEL_DOCUMENT = "Document";

export const FORM_TYPE = "zor";

export const SEC_NAMING = {
  [SECTION_KEY_ENTITY]: SECTION_LABEL_ENTITY,
  [SECTION_KEY_POC]: SECTION_LABEL_POC,
  [SECTION_KEY_UNIQUE]: SECTION_LABEL_UNIQUE,
  [SECTION_KEY_DRIVERS]: SECTION_LABEL_DRIVERS,
  [SECTION_KEY_VEHICLES]: SECTION_LABEL_VEHICLES,
  [SECTION_KEY_BUILD_OUT]: SECTION_LABEL_BUILD_OUT,
  [SECTION_KEY_PAYROLL]: SECTION_LABEL_PAYROLL,
  [SECTION_KEY_SUBCONTRACTOR]: SECTION_LABEL_SUBCONTRACTOR,
  [SECTION_KEY_PROPERTY]: SECTION_LABEL_PROPERTY,
  [SECTION_KEY_EQUIPMENT]: SECTION_LABEL_EQUIPMENT
};

export const SEC_ZEE_NAMING = {
  [SECTION_KEY_ENTITY]: SECTION_LABEL_ENTITY,
  [SECTION_KEY_POC]: SECTION_LABEL_POC_ZEE,
  [SECTION_KEY_UNIQUE]: SECTION_LABEL_UNIQUE,
  [SECTION_KEY_DRIVERS]: SECTION_LABEL_DRIVERS,
  [SECTION_KEY_VEHICLES]: SECTION_LABEL_VEHICLES,
  [SECTION_KEY_BUILD_OUT]: SECTION_LABEL_BUILD_OUT,
  [SECTION_KEY_PAYROLL]: SECTION_LABEL_PAYROLL,
  [SECTION_KEY_SUBCONTRACTOR]: SECTION_LABEL_SUBCONTRACTOR,
  [SECTION_KEY_PROPERTY]: SECTION_LABEL_PROPERTY,
  [SECTION_KEY_EQUIPMENT]: SECTION_LABEL_EQUIPMENT
};

export const DOC_KEY_LOSS_RUNS = "loss-runs";
export const DOC_LABEL_LOSS_RUNS = "Loss runs";
export const DOC_KEY_EXISTING_POLICIES = "existing-policies";
export const DOC_LABEL_EXISTING_POLICIES = "Existing Policies";
export const DOC_KEY_QUOTES = "quotes";
export const DOC_LABEL_QUOTES = "Quotes";
export const DOC_KEY_APPLICATION = "application";
export const DOC_LABEL_APPLICATION = "Application";
export const DOC_KEY_DRIVER_SCHEDULE = "driver-schedule";
export const DOC_LABEL_DRIVER_SCHEDULE = "Driver Schedule";
export const DOC_KEY_VEHICLE_SCHEDULE = "vehicle-schedule";
export const DOC_LABEL_VEHICLE_SCHEDULE = "Vehicle Schedule";
export const DOC_KEY_FINANCIAL = "financial";
export const DOC_LABEL_FINANCIAL = "12 Month Profit and Loss";
export const DOC_KEY_OTHER = "other";
export const DOC_LABEL_OTHER = "Other";
export const DOC_KEY_COI = "COI";
export const DOC_LABEL_COI = "Certificate of Insurance (COI)";
export const DOC_KEY_COI_EOI = "COPI/EOI";
export const DOC_LABEL_COI_EOI =
  "Certificate of Property Insurance (COPI/EOI) - ACORD 28";

export const DOC_NAMING = {
  [DOC_KEY_LOSS_RUNS]: DOC_LABEL_LOSS_RUNS,
  [DOC_KEY_EXISTING_POLICIES]: DOC_LABEL_EXISTING_POLICIES,
  [DOC_KEY_QUOTES]: DOC_LABEL_QUOTES,
  [DOC_KEY_APPLICATION]: DOC_LABEL_APPLICATION,
  [DOC_KEY_DRIVER_SCHEDULE]: DOC_LABEL_DRIVER_SCHEDULE,
  [DOC_KEY_VEHICLE_SCHEDULE]: DOC_LABEL_VEHICLE_SCHEDULE,
  [DOC_KEY_FINANCIAL]: DOC_LABEL_FINANCIAL,
  [DOC_KEY_OTHER]: DOC_LABEL_OTHER,
  [DOC_KEY_COI]: DOC_LABEL_COI,
  [DOC_KEY_COI_EOI]: DOC_LABEL_COI_EOI
};

export const TEMPLATE_KEY_NEW_FRANCHISEE = "new_franchisee";
export const TEMPLATE_LABEL_NEW_FRANCHISEE = "New Franchisee";
export const TEMPLATE_KEY_MID_TERM_AUDIT = "mid_term_audit";
export const TEMPLATE_LABEL_MID_TERM_AUDIT = "Mid-term Audit";
export const TEMPLATE_KEY_END_OF_YEAR_RENEWAL = "end_of_year_renewal";
export const TEMPLATE_LABEL_END_OF_YEAR_RENEWAL = "End of year Renewal";
export const TEMPLATE_KEY_MISSING_DATA_FOLLOW_UP = "missing_data_follow_up";
export const TEMPLATE_LABEL_MISSING_DATA_FOLLOW_UP = "Missing data follow-up";
export const TEMPLATE_KEY_ENDORSEMENT_REQUEST = "endorsement_request";
export const TEMPLATE_LABEL_ENDORSEMENT_REQUEST = "Endorsement Request";

export const TEMPLATE_NAMING = {
  [TEMPLATE_KEY_NEW_FRANCHISEE]: TEMPLATE_LABEL_NEW_FRANCHISEE,
  [TEMPLATE_KEY_MID_TERM_AUDIT]: TEMPLATE_LABEL_MID_TERM_AUDIT,
  [TEMPLATE_KEY_END_OF_YEAR_RENEWAL]: TEMPLATE_LABEL_END_OF_YEAR_RENEWAL,
  [TEMPLATE_KEY_MISSING_DATA_FOLLOW_UP]: TEMPLATE_LABEL_MISSING_DATA_FOLLOW_UP,
  [TEMPLATE_KEY_ENDORSEMENT_REQUEST]: TEMPLATE_LABEL_ENDORSEMENT_REQUEST
};

export const PREFERRED_CONTACT_METHOD_PHONE = "phone";
export const PREFERRED_CONTACT_METHOD_EMAIL = "email";
export const PREFERRED_CONTACT_METHOD_SMS = "sms";

export const PREFERRED_CONTACT_METHOD_LIST = [
  {
    value: PREFERRED_CONTACT_METHOD_PHONE,
    label: "Phone"
  },
  {
    value: PREFERRED_CONTACT_METHOD_EMAIL,
    label: "Email"
  },
  {
    value: PREFERRED_CONTACT_METHOD_SMS,
    label: "SMS"
  }
];

export const ROLE_FOUNDER = "founder";
export const ROLE_CEO = "ceo";
export const ROLE_CONTROLLER = "controller";
export const ROLE_CFO = "cfo";
export const ROLE_DIRECTOR = "director";
export const ROLE_PRESIDENT = "president";
export const ROLE_DEVELOPER = "developer";
export const ROLE_ONBOARDING = "onboarding";
export const ROLE_ACCOUNTING = "accounting";

export const ROLE_LIST = [
  {
    value: ROLE_FOUNDER,
    label: "Founder"
  },
  {
    value: ROLE_CEO,
    label: "CEO"
  },
  {
    value: ROLE_CONTROLLER,
    label: "Controller"
  },
  {
    value: ROLE_CFO,
    label: "CFO"
  },
  {
    value: ROLE_DIRECTOR,
    label: "Director"
  },
  {
    value: ROLE_PRESIDENT,
    label: "President"
  },
  {
    value: ROLE_DEVELOPER,
    label: "Developer"
  },
  {
    value: ROLE_ONBOARDING,
    label: "Onboarding"
  },
  {
    value: ROLE_ACCOUNTING,
    label: "Accounting"
  }
];

export const ZOR_VIEW_ACTIVE = "ZOR_VIEW_ACTIVE";
export const ZOR_VIEW_ARCHIVED = "ZOR_VIEW_ARCHIVED";
