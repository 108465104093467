import React, { useState, useEffect } from "react";
import "./style.scss";
import { Loading, Card } from "../../../components";
import { Table, Input, notification, Button, Modal } from "antd";
import { useAppState } from "../../../context";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, post } from "../../../utils/axios";
import {
  UsergroupAddOutlined,
  UsergroupDeleteOutlined
} from "@ant-design/icons";
import OnboardingForm from "./onboarding-form";
import DeleteSettingForm from "./delete-setting-form";
import { PERMISSION_TABLE } from "../../../utils/permission";
import {
  REQUIREMENT_STATUS_DASHBOARD,
  REQUIREMENT_STATUS_ONBOARD,
  STATUS_PENDING,
  STATUS_COMPLETE
} from "../../../assets/const/status";
import { getHostTag } from "../../../utils/host";
import { ZOR_VIEW_ACTIVE, ZOR_VIEW_ARCHIVED } from "@assets/const/ui";
const { Search } = Input;

const MODAL_TYPE_ONBOARD = "MODAL_TYPE_ONBOARD";
const MODAL_TYPE_DELETE = "MODAL_TYPE_DELETE";
const MODAL_TYPE_NONE = "MODAL_TYPE_NONE";

export default function FranchisorOverview(props) {
  const { franchisorList, setFranchisorList } = useAppState();
  const { getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin, setFranchisorAdmin, authSetting } = useAppState();
  const [searchStr, setSearchStr] = useState("");

  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(MODAL_TYPE_NONE);

  let history = useHistory();
  const dataSource = [];
  if (franchisorList && franchisorList.length > 0) {
    for (const item of franchisorList) {
      if (
        JSON.stringify(item).toLowerCase().indexOf(searchStr.toLowerCase()) >= 0
      ) {
        dataSource.push({
          ...item,
          key: item._id,
          personal_infor_address: item["personal_infor_address"],
          personal_infor_business_name: item["personal_infor_business_name"],
          personal_infor_city: item["personal_infor_city"],
          dba: item["dba"],
          countContacts: (item?.poc_contacts || []).length,
          coverageScore: (item.coverageScore || 0).toFixed(2),
          personal_infor_state: item["personal_infor_state"],
          personal_infor_zip: item["personal_infor_zip"],
          franchisee_count: item["franchisees"],
          customer_count: item["rikorCustomers"],
          check_franchisee_imported:
            item["franchisees"] > 0 ? STATUS_COMPLETE : STATUS_PENDING,
          check_risk_profile: item?.naics_classes
            ?.map((item) => item.code)
            .join(","),
          check_insurance_requirement: handleCheckRequirement(item),
          check_fms_integration:
            item["credential"]?.length > 0 ? STATUS_COMPLETE : STATUS_PENDING,
          insuranceVerification: item?.rmsInsVer ? "completed" : "pending"
        });
      }
    }
  }

  function handleCheckRequirement(item) {
    let count = 0;
    if (item?.commercial_general_liability_required) {
      count++;
    }

    if (item?.automotive_liability_required) {
      count++;
    }
    if (item?.workers_compensation_required) {
      count++;
    }
    if (item?.umbrella_subrogration_required) {
      count++;
    }
    if (item?.crime_required) {
      count++;
    }
    if (item?.garage_required) {
      count++;
    }
    if (item?.epli_required) {
      count++;
    }
    if (item?.cyber_required) {
      count++;
    }

    if (item?.pro_liab_required) {
      count++;
    }

    if (item?.pol_liab_required) {
      count++;
    }

    if (item?.im_required) {
      count++;
    }

    if (item?.pro_ins_required) {
      count++;
    }
    return count;
  }

  useEffect(() => {
    handleFetchFranchisor();
  }, []);

  useEffect(() => {
    if (franchisorAdmin) {
      setCheckedList([franchisorAdmin]);
    } else {
      setCheckedList([]);
    }
  }, [franchisorAdmin]);

  async function handleFetchFranchisor() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/franchisor`,
        {
          host: getHostTag(),
          archived: props.viewMode === ZOR_VIEW_ARCHIVED ? true : null
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        setFranchisorList(result.data.requirements);
        if (franchisorAdmin) {
          franchisorAdmin["key"] = franchisorAdmin._id;
          setCheckedList([franchisorAdmin]);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleNotification(
        "Failed!",
        "Franchisors loading is failed. Please contact us."
      );
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  function renderStatus(status) {
    if (status) {
      switch (status.toLowerCase()) {
        case "completed":
          return <div className="status-container success">COMPLETED</div>;
        case "pending":
          return <div className="status-container info">PENDING</div>;
        default:
          return <div className="status-container info">PENDING</div>;
      }
    }
    return <div className="status-container info">PENDING</div>;
  }

  function renderSectionStatus(status) {
    switch (status) {
      case STATUS_COMPLETE:
        return <div className="status-container success">COMPLETED</div>;
      case STATUS_PENDING:
        return <div className="status-container info">PENDING</div>;
      default:
        return <div className="status-container info">PENDING</div>;
    }
  }
  function renderSectionArray(value) {
    if (value) {
      return (
        <div className="tag-container">
          {(value || []).map((item) => (
            <div className="tag-item">{item}</div>
          ))}
        </div>
      );
    }
    return null;
  }

  const columns = [
    {
      title: "Entity Name",
      dataIndex: "personal_infor_business_name",
      key: "personal_infor_business_name"
    },
    {
      title: "DBA",
      dataIndex: "dba",
      key: "dba"
    },
    {
      title: "Franchisees",
      dataIndex: "franchisee_count",
      key: "franchisee_count"
    },
    {
      title: "Rikor Customer",
      dataIndex: "customer_count",
      key: "customer_count"
    },
    // {
    //   title: "Franchisees Imported",
    //   dataIndex: "check_franchisee_imported",
    //   key: "check_franchisee_imported",
    //   render: renderSectionStatus,
    // },
    {
      title: "Average Compliance Score",
      dataIndex: "coverageScore",
      key: "coverageScore"
    },
    {
      title: "Contacts",
      dataIndex: "countContacts",
      key: "countContacts"
    },
    {
      title: "Insurance Requirements",
      dataIndex: "check_insurance_requirement",
      key: "check_insurance_requirement"
    },
    {
      title: "FMS Integration",
      dataIndex: "check_fms_integration",
      key: "check_fms_integration",
      render: renderSectionStatus
    },
    {
      title: "Insurance Verification",
      dataIndex: "insuranceVerification",
      key: "insuranceVerification",
      render: renderStatus,
      filters: [
        {
          text: "Completed",
          value: "completed"
        },
        {
          text: "Pending",
          value: "pending"
        }
      ],
      onFilter: (value, record) => record.insuranceVerification === value
    }
  ];

  const rowSelection = {
    selectedRowKeys: checkedList.map((item) => item.key),
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedList(selectedRows);
    }
  };

  const onRow = (row) => ({
    onClick: () => {
      setFranchisorAdmin(row);
      history.push({
        pathname: "/admin/franchisees",
        state: { requirementId: row._id }
      });
    }
  });

  function handleOnboard() {
    setIsOpen(true);
    setModalType(MODAL_TYPE_ONBOARD);
  }

  async function handleRemove(config) {
    setIsOpen(false);
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      for (const item of checkedList) {
        await post(`requirement/${item._id}/delete`, config, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    handleFetchFranchisor();
  }

  function handleRemoveClick() {
    setModalType(MODAL_TYPE_DELETE);
    setIsOpen(true);
  }

  function renderModal() {
    return (
      <Modal
        className={
          modalType === MODAL_TYPE_ONBOARD
            ? "custom-modal_onboard"
            : "custom-modal_delete"
        }
        title={
          modalType === MODAL_TYPE_ONBOARD
            ? "Onboarding Franchisor"
            : "Delete/Archive Franchisor"
        }
        open={isOpen}
        footer={null}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
      >
        {modalType === MODAL_TYPE_ONBOARD && (
          <OnboardingForm
            onClose={() => setIsOpen(false)}
            onRefresh={handleFetchFranchisor}
          />
        )}
        {modalType === MODAL_TYPE_DELETE && (
          <DeleteSettingForm
            onClose={() => setIsOpen(false)}
            onOk={handleRemove}
          />
        )}
      </Modal>
    );
  }

  return (
    <div className="admin_sors">
      <Card title="Franchisors">
        <div className="admin_sors-filter">
          <div className="admin_sors-filter-search">
            <Search
              value={searchStr}
              onChange={(evt) => setSearchStr(evt.target.value)}
              placeholder="Franchisor name, email, or entity"
              enterButton="Search Franchisors"
            />
          </div>
          <div className="admin_sors-filter-action">
            {PERMISSION_TABLE["FRANCHISOR_ADD"][authSetting.userPermission] >
              0 &&
              props.viewMode === ZOR_VIEW_ACTIVE && (
                <Button onClick={handleOnboard} type="primary">
                  <UsergroupAddOutlined /> Add Franchisor
                </Button>
              )}
            {PERMISSION_TABLE["FRANCHISOR_DELETE"][authSetting.userPermission] >
              0 &&
              checkedList?.length === 1 && (
                <Button
                  className="btn-delete"
                  danger
                  onClick={handleRemoveClick}
                  type="primary"
                >
                  <UsergroupDeleteOutlined /> Delete Franchisor
                </Button>
              )}
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          onRow={onRow}
          columns={columns}
          dataSource={dataSource}
          pagination={{ defaultPageSize: 100 }}
        />
      </Card>
      <Loading loading={loading} />
      {renderModal()}
    </div>
  );
}
